.faq-category{
  &:not(:last-child){
    margin-bottom: $spacer*4;
  }
  
  &-title{
    @extend .border-bottom;
    @extend .pb-3;
  }

  &-collapse{
    @extend .border-bottom;
    @extend .d-flex; 
    @extend .justify-content-between; 
    @extend .align-items-center;
    @extend .pb-3;
    @extend .pt-2;

    &[data-toggle="collapse"] .rotate {
      transition: all 0.2s ease-out;
      margin-left: 10px;
    }
    &[aria-expanded="false"] .rotate {
      transform: rotate(90deg);
    }
    &[aria-expanded="true"] .rotate {
      transform: rotate(-90deg);
    }
  }
}