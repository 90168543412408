* {
    font-family: "Poppins"!important;
}
img {
    max-width: 100%;
}
p {
    line-height: 28px;
}
.form-control{
  font-size: .875rem;
  height: 40px;
}
.form-inline{
  select{
    margin-bottom: 10px;
  }
}

.page-title{
  font-size: 30px;
  color: $black;
  font-weight: bold;
  @include media-breakpoint-up(sm){
    font-size: 40px;
  }
  @include media-breakpoint-up(md){
    font-size: 45px;
  }
  @include media-breakpoint-up(xl){
    font-size: 55px;
  }
}
.page-subtitle {
  font-size: 18px;
  font-weight: 600;
  @include media-breakpoint-up(sm){
    font-size: 22px;
  }
  @include media-breakpoint-up(md){
    font-size: 26px;
  }
  @include media-breakpoint-up(xl){
    font-size: 30px;
  }
}

main, #content, #connexion{
  min-height: 100vh;
}


$colors: ("yellowgreen": #9acd32, "orange": $orange);

@each $key, $color in $colors {
  .text-#{$key}{
    color: $color;
  }
}