.text-decoration-underline {
    text-decoration: underline;
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
