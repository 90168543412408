#forget-pwd{
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md){
    align-items: flex-start;
  }

  a{
    margin-top: 15px;
  }
}
