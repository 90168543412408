// Here you can add some css property to bootstrap classes

/*** BUTTONS ***/
.btn {
  font-weight: 600;
  color: $white;
  border: none;
  font-size: 18px;
  padding: 10px 30px;
}

@each $color, $value in $theme-colors {
  @if $color != light {
    .btn-#{$color} {
      color: $white;
      background-color: $value;
      border: 2px solid $value;
      
      &:hover {
          background-color: $dark;
          border: 2px solid $dark;
          color: $light;
      }
    }
  }
}

/*** LINKS ***/
a{
  &:hover{
    text-decoration: none;
  }
  &.text-dark:hover{
    color: $primary !important;
  }
}

/*** CONTAINER-FLUID ***/
.container-fluid{
  padding-left: 20px;
  padding-right: 20px;

  @include media-breakpoint-up(md){
    padding-left: 40px;
    padding-right: 40px;
  }
}